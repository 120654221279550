import axios from "axios";
import TokenService from "./tokenService";

const API_BASE_URL = process.env.REACT_APP_BASE_URL

const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "co2ChannelId": process.env.REACT_APP_CHANNEL_ID
    }
})

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken()
        if (token) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${token}`,
            };
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (res) => {
        return res
    },
    async (err) => {
        const originalConfig = err.config

        const config = err?.config

        if (originalConfig.url !== '/login' ) {
            if (err?.response?.status === 401 && !config?.sent) {
                originalConfig._retry = true
                config.sent = true
                try {
                    const { data } = await instance.post('/token/refresh', {
                        refresh_token: TokenService.getLocalRefreshToken()
                    })

                    TokenService.updateLocalAccessToken(data)

                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${data?.token}`,
                    };

                    return axios(config)
                } catch (_error) {
                    TokenService.removeUser()
                    window.location.replace('/logout')
                    return Promise.reject(_error)
                }
            }
        }
        return Promise.reject(err)
    }
)

export default instance