import {Flex} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";

export default function AuthLayout() {
    return (
        <Flex
            flexDirection={"column"}
            width={"100%"}
            height={"100vh"}
            backgroundColor={"gray.200"}
            justifyContent={"center"}
            alignItems={"center"}>
            <Outlet />
        </Flex>
    )
}