import {Box, Flex,  Select} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {getChannels} from "./orderService";
import {useFilter} from "../../contexts/filterContext";


const OrderFilter = () => {
    const [channels, setChannels] = useState([])
    const { channel, setChannel }  = useFilter()

    useEffect(() => {
        (async () => {
            const defaultChannels = [{id: '', name: 'All Channels'}]
            try {
                const { data } = await getChannels()
                setChannels([...defaultChannels, ...data["hydra:member"]])
            } catch (e) {

            }
        })()
    }, [])

  return (
      <Box
          bgGradient="linear(to-t, blue.50, blue.100)"
          p={'10px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          backdropFilter='saturate(200%) blur(50px)'
          boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
          border='2px solid'
          borderRadius='10px'
          borderColor={'blackAlpha.200'}
      >
        <Flex
            direction={{ sm: "column", md: "row" }}
            mx='1.5rem'
            maxH='330px'
            justifyContent={{ sm: "center", md: "space-between" }}
            align='center'
            >
            <Select
                marginRight={'10px'}
                placeholder={'Channel'}
                defaultValue={channel}
                onChange={(event) => setChannel(event.target.value)}
            >
                { channels &&
                    channels.map((channelItem) => {
                        return (
                            <option key={channelItem.name} value={channelItem.id}>{ channelItem.name}</option>
                        )
                    })
                }
            </Select>
        </Flex>
      </Box>
  )
}

export default OrderFilter