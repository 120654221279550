import * as yup from "yup"

export const validationSchema = yup.object({
    firstName: yup.string().min(3).required(),
    lastName: yup.string().min(3).required(),
    email: yup.string().email().required(),
    companyName: yup.string().required(),
    certificateText: yup.string().required(),
    quantity: yup.number().required(),
    totalPrice: yup.number().required(),
    projectId: yup.string().required()
})