import React from 'react';
import {
    Box,
    Flex,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    useDisclosure,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiPlusCircle,
} from 'react-icons/fi';

const LinkItems = [
    { name: 'Dashboard', icon: FiHome, url: '/app/dashboard' },
    { name: 'Siparişler', icon: FiTrendingUp, url: '/app/orders' },
    { name: 'Yeni Sipariş', icon: FiPlusCircle, url: '/app/orders/add' },
    { name: 'Sertifikalar', icon: FiCompass, url: '/app/certificates' },
    { name: 'Faturalar', icon: FiStar, url: '/app/invoices' },
];


const SideBar = () => {
    const { isOpen,  onClose } = useDisclosure()

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

const SidebarContent = ({ onClose, ...rest } ) => {
    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            { LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} url={link.url} >
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

const NavItem = ({ icon, url ,children, ...rest }) => {
    return (
        <Link href={url} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};


export default SideBar