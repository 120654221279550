import { Navigate } from 'react-router-dom';
import DefaultLayout from "./pages/DefaultLayout";
import AuthLayout from "./pages/Auth/authLayout";
import Login from "./pages/Auth/login";
import Orders from "./pages/Order/oders";
import AddOrder from "./pages/Order/AddOrder";
import {CertificateList} from "./pages/Certificate/CertificateList";
import {EditOrder} from "./pages/Order/EditOrder";
import {InvoiceList} from "./pages/Invoice/InvoiceList";
import {FilterProvider} from "./contexts/filterContext";

const routes = (isLoggedIn) => [
    {
        path: '/app',
        element: isLoggedIn ? <DefaultLayout /> : <Navigate to={'/login'} />,
        children: [
            { path: '/app/dashboard', element: <div>Dashboard</div> },
            { path: '/app/orders', element: <FilterProvider><Orders /></FilterProvider> },
            { path: '/app/orders/add', element: <AddOrder /> },
            { path: '/app/orders/edit/:orderId', element: <EditOrder /> },
            { path: '/app/certificates', element: <CertificateList /> },
            { path: '/app/invoices', element:  <InvoiceList />},
        ]
    },
    {
        path: '/',
        element: !isLoggedIn ? <AuthLayout /> : <Navigate to={'/app/dashboard'} />,
        children: [
            { path: 'login', element: <Login /> },
            { path: '/', element: <Navigate to={'/login'}/> }
        ]
    }
]

export default routes;