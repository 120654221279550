import api from "../../services/api";

const defaultCertificateText = 'This certificate confirms the offset of carbon emissions of your business via additional carbon offset projects.'

const getOrders = async ({page, channelFilter}) => {
    return await api(`/orders?order[createdAt]=desc&page=${page}${channelFilter}`)
}

const getProjects = async () => {
    try {
        const { data } = await api(`projects?locale=en&isSale[]=1&isSale[]=0&isActive[]=1`)

        return {
            items: data['hydra:member']
        }
    } catch (e) {
        console.log('error')
    }
}

const createOrder = async (payload) => {
    try {
        const { data } = await api.post(`/in_house_orders`, payload)

        return data
    } catch (e) {
        console.log('errors', e)
    }
}

const updateOrder = async ({payload, orderId}) => {
    return await api.put(`/in_house_orders/${orderId}`, payload)
}

const getChannels = async () => {
    return await api(`/channels`)
}

export {
    getProjects,
    createOrder,
    defaultCertificateText,
    updateOrder,
    getChannels,
    getOrders
}