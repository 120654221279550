import {createContext, useContext, useEffect, useState} from "react";
import TokenService from "../services/tokenService";

const AuthContext = createContext(null)

const localUser = localStorage.getItem('user')

const defaultAuth = localUser ? {
    isLoggedIn: true,
    user: localUser
} : { isLoggedIn: false, user: null }

export const AuthProvider = ({ children }) => {
    const [authed, setAuthed] = useState(defaultAuth)

    useEffect(() => {
        if (authed.isLoggedIn) {
            return
        }
    }, [authed])

    const doLogin = (user) => {
        setAuthed({
            isLoggedIn: true,
            user
        })
    }

    const doLogOut = async () => {
        await TokenService.removeUser()
        setAuthed({
            isLoggedIn: false,
            user: null
        })

    }


    return <AuthContext.Provider value={{ authed, setAuthed, doLogin, doLogOut }}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext);