import {
    Table,
    TableContainer,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Spinner,
    Flex,
    Tooltip,
    IconButton,
    Text, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper
} from "@chakra-ui/react";
import {getCertificates} from "./certificateService";
import {useEffect, useState} from "react";
import {CertificateTableItem} from "./CertificateTableItem";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

const totalPage = (totalItems, limit) => {
    if (totalItems === 0) {
        return totalItems
    }

    return  Math.round( totalItems / limit )
}


export const CertificateList = () => {
    const [certificates, setCertificates] = useState([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [canPreviousPage, setCanPreviousPage] = useState(true)
    const [pageCount, setPageCount] = useState(1)

    useEffect(() => {
        getCertificateStates(page)
        setCanPreviousPage(page !== 1)
    },[page])

    const getCertificateStates = async ( page ) => {
        setLoading(true)

        try {
            const { data } = await getCertificates({ page: page})

            setCertificates(data.data)
            setPageCount(totalPage(data.total, 50))
            setLoading(false)

        } catch (e) {
            console.log(e)
        }
    }

    const goToPage = (page) => {
        setPage(page)
    }



    return (
        <>
            <TableContainer shadow={"md"} border={"md"} mt={"1rem"}>
                <Table size={"sm"} variant={"striped"}>
                    <Thead>
                        <Tr>
                            <Th>Certificate No</Th>
                            <Th>Customer</Th>
                            <Th>CreatedAt</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        { loading && <Tr><Td colSpan={8}><Spinner size={"xl"} /></Td></Tr> }
                        { !loading &&
                            certificates.map((certificate) => {
                                return (
                                    <CertificateTableItem key={certificate.c_id} certificate={certificate} />
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex justifyContent={"space-between"} m={4} alignItems={"center"}>
                <Flex>
                    <Tooltip label={"First Page"}>
                        <IconButton
                            onClick={() => goToPage(1)}
                            isDisabled={!canPreviousPage}
                            icon={<ArrowLeftIcon h={3} w={3} />}
                        ></IconButton>
                    </Tooltip>
                    <Tooltip label={"Previous Page"}>
                        <IconButton
                            onClick={() => goToPage(page - 1 )}
                            isDisabled={!canPreviousPage}
                            icon={<ChevronLeftIcon h={6} w={6} />}
                        >
                        </IconButton>
                    </Tooltip>
                </Flex>

                <Flex alignItems={"center"}>
                    <Text flexShrink={"0"} mr={8}>
                        Page{" "}
                        <Text fontWeight={"bold"} as={"span"}>
                            { page }
                        </Text>{" "}
                        of{" "}
                        <Text fontWeight={"bold"} as={"span"}>
                            { pageCount }
                        </Text>
                    </Text>
                    <Text flexShrink="0">Go to page:</Text>{" "}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={28}
                        min={1}
                        max={pageCount}
                        onChange={(value) => {
                            const newPage = value ? value : 1
                            goToPage(newPage)
                        }}
                        defaultValue={page}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>

                <Flex>
                    <Tooltip label="Next Page">
                        <IconButton
                            onClick={() => goToPage(Math.round(page) + 1)}
                            isDisabled={page === pageCount}
                            icon={<ChevronRightIcon h={6} w={6} />}
                        />
                    </Tooltip>
                    <Tooltip label="Last Page">
                        <IconButton
                            onClick={() => goToPage(pageCount )}
                            isDisabled={page === pageCount}
                            icon={<ArrowRightIcon h={3} w={3} />}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>

            </Flex>
        </>
    )
}