import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import {ChakraProvider} from "@chakra-ui/react";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./contexts/authContext";
import 'react-toastify/dist/ReactToastify.css';

/*
const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute><DefaultLayout /></ProtectedRoute>,
        children: [
            {index: true, element: <div>adasd</div>}
        ]
    },
    {
        path: '/auth',
        element: <AuthLayout/>,
        children: [
            { index: true, element: <Login /> }
        ]
    }
])


 */

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
      <ChakraProvider>
          <AuthProvider>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
          </AuthProvider>
      </ChakraProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
