import {Button, ButtonGroup, IconButton, Td, Tr} from "@chakra-ui/react";
import {EditIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import moment from "moment";

export const OrderItem = ( { order } ) => {

    const navigate = useNavigate()

    return (
      <Tr>
          <Td>{ order.state }</Td>
          <Td>{ order.companyName ? order.companyName : order.firstName + ' ' + order.lastName }</Td>
          <Td>{ moment(order.createdAt).format('LL') }</Td>
          <Td>{ order.amount }</Td>
          <Td>{ order.amountOfPayment } {order.currencyCode}</Td>
          <Td>
              <ButtonGroup size='sm' isAttached variant={'outline'} >
                  <Button onClick={ () => navigate(`/app/orders/edit/${order.id}`) }>Düzenle</Button>
                  <IconButton onClick={ () => navigate(`/app/orders/edit/${order.id}`) } aria-label={ 'Sertifikayı Götüntüle' } icon={<EditIcon />} />
              </ButtonGroup>
          </Td>
      </Tr>
  )
}