import {Box, Grid, GridItem} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/Shared/SideBar";
import Header from "../components/Shared/Header";
const DefaultLayout = (  ) => {
    return (
        <Grid
            templateAreas={`
                    "header header"
                    "nav main"
                    "footer footer"
                    `}
            gridTemplateRows={'50px 1fr 30px'}
            gridTemplateColumns={'250px 1fr'}
            h='100vh'
            gap={2}
        >
            <GridItem area={'header'}>
                <Header />
            </GridItem>
            <GridItem area={'nav'}>
                <SideBar><></></SideBar>
            </GridItem>
            <GridItem area={'main'} >
                <Box mt={"1rem"} maxW={"1080px"} mx={"auto"}>
                    <Outlet />
                </Box>
            </GridItem>
            <GridItem area={'footer'}>Footer</GridItem>
        </Grid>
    )
}

export default DefaultLayout