import React from 'react';
import {useAuth} from "./contexts/authContext";
import { useRoutes } from "react-router-dom"
import routes from "./routes";

function App() {
  const { authed } = useAuth()
  const isLoggedIn = authed.isLoggedIn
  const routing = useRoutes(routes(isLoggedIn))

  return (
      <>
        {routing}
      </>
  );
}

export default App;
