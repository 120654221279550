import {Button, ButtonGroup, IconButton, Td, Tr} from "@chakra-ui/react";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

export const InvoiceListItem = ({ invoice }) => {

    const viewInvoice = id => {
        window.open(`${process.env.REACT_APP_BASE_URL}/invoices/${id}/show`, '_blank', 'noopener,noreferrer')
    }

    const navigate = useNavigate()

    return (
        <Tr>
            <Td>{invoice.invoiceNumber}</Td>
            <Td>{invoice.invoiceDate}</Td>
            <Td>{invoice.customer}</Td>
            <Td>{invoice.total} {invoice.currencyCode}</Td>
            <Td>
                <ButtonGroup size='sm' isAttached variant={'outline'} >
                    <Button onClick={ () => viewInvoice(invoice.id)}>Görüntüle</Button>
                    <IconButton onClick={ () => viewInvoice(invoice.id)} aria-label={ 'Faturayı Götüntüle' } icon={<ViewIcon />} />
                </ButtonGroup>
                <ButtonGroup size='sm' isAttached variant={'outline'} >
                    <Button onClick={ () => navigate(`/app/orders/edit/${invoice.orderNumber}`) }>Düzenle</Button>
                    <IconButton onClick={ () => navigate(`/app/orders/edit/${invoice.orderNumber}`) } aria-label={ 'Sertifikayı Götüntüle' } icon={<EditIcon />} />
                </ButtonGroup>
            </Td>
        </Tr>
    )
}