import jwtDecode from "jwt-decode";

const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.refresh_token
}

const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.token
}

const updateLocalAccessToken = (token) => {
    return localStorage.setItem('user', JSON.stringify(token))
}

const decodeToken = async (token) => {
    try {
        return await jwtDecode(token)
    } catch (e) {
        return e
    }
}

const getUser = () => {
    return localStorage.getItem('user')
}

const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user))
}

const removeUser = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user')
}

const TokenService = {
    getLocalAccessToken,
    getLocalRefreshToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
    decodeToken
}

export default TokenService