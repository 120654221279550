import {
    Avatar,
    Box, Button,
    chakra,
    FormControl, FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Stack,
} from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { FaUserAlt, FaLock } from "react-icons/fa";
import {validationSchema} from "./loginValidationSchema";
import { useState} from "react";
import {useFormik} from "formik";
import api from "../../services/api";
import {useAuth} from "../../contexts/authContext";
import TokenService from "../../services/tokenService";
import {toast, ToastContainer} from "react-toastify";

const CFaUserAlt = chakra(FaUserAlt)
const CFaLock = chakra(FaLock);

const Login = () => {
    const { doLogin, authed } = useAuth()
    const [showPassword, setShowPassword] = useState(false)
    const handleShowPasswdClick = () => setShowPassword(!showPassword)

    const { signIn ,handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        mode: 'onBlur',
        validationSchema,
        onSubmit: async values => {
            try {
                const { data } = await api.post('/login', values)
                const { roles } = await TokenService.decodeToken(data.token)

                if (!roles.includes('ROLE_SUPERADMIN')) {
                    return
                }
                TokenService.setUser(data)
                doLogin({data})
            } catch (e) {
                toast.error('Invalid username or password')
            }
        }
    })
    
    return authed.isLoggedIn ? (
        <Navigate to={"/app/dashboard"} />
    ) : (
        <Stack
            flexDir="column"
            mb="2"
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Avatar bg={"teal.500"} />
            <Heading color={"teal.400"}>Welcome</Heading>
            <Box minW={{ base: "90%", md: "480px"}}>
                <form onSubmit={handleSubmit}>
                    <Stack
                        spacing={4}
                        p={"1rem"}
                        backgroundColor={"whiteAlpha.900"}
                        boxShadow={"md"}
                    >
                        <FormControl
                            isInvalid={!!errors.username}
                            isRequired
                        >
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents={"none"}
                                    children={<CFaUserAlt color={"gray.300"} />}
                                />
                                <Input
                                    type={"email"}
                                    placeholder={"Username"}
                                    name={"username"}
                                    onChange={handleChange}
                                    value={values.username}
                                    ref={signIn}
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors?.username}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={!!errors?.password}
                            isRequired>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents={"none"}
                                    color={"gray.300"}
                                    children={<CFaLock color={"gray.300"} />}
                                ></InputLeftElement>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    placeholder={"Password"}
                                    name={"password"}
                                    onChange={handleChange}
                                    value={values.password}
                                    ref={signIn}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={handleShowPasswdClick}>
                                        {showPassword ? "Hide" : "Show"}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>{errors?.password}</FormErrorMessage>
                        </FormControl>
                        <Button
                            borderRadius={0}
                            type="submit"
                            variant="solid"
                            colorScheme="teal"
                            width="full"
                            disabled={!!errors.username || !!errors.password}
                            >
                            Login
                        </Button>
                    </Stack>
                </form>
            </Box>
            <ToastContainer theme={"colored"} />
        </Stack>
    )
}

export default Login