import {useNavigate, useParams} from "react-router-dom";
import api from "../../services/api";
import {useEffect, useState} from "react";
import {getProjects, updateOrder} from "./orderService";
import {useFormik} from "formik";
import {validationSchema} from "./addOrderValidationSchema";
import {toast, ToastContainer} from "react-toastify";
import {
    Box, Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input, InputGroup, InputRightElement, Select, SelectField,
    Stack, Switch,
    Textarea
} from "@chakra-ui/react";

export const EditOrder = () => {

    const { orderId } = useParams()

    const [order, setOrder] = useState(false)
    const [projects, setProject] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {

        (async () => {
            const { items }  = await getProjects()
            setProject(items)
        })();

        (async () => {
            const { data } = await api(`/in_house_orders/${orderId}`)
            setOrder(order => ({...data}))
        })();

    }, [orderId])

    const {editOrder, handleSubmit, handleChange, values, errors } = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: order.firstName,
            lastName: order.lastName,
            email: order.email,
            companyName: order.companyName,
            certificateText: order.certificateText,
            quantity: order.quantity,
            totalPrice: order.totalPrice,
            currencyCode: order.currencyCode,
            projectId: order.projectId,
            isInvoice: order.isInvoice,
            taxNumber: order.taxNumber
        },
        mode: 'onBlur',
        validationSchema,
        onSubmit: async values => {
            setLoading(true)
            try {
                await updateOrder({payload: values, orderId})
                setLoading(false)
                toast.success(`Sipariş Güncellendi`)
                setTimeout(() => {
                    navigate('/app/orders')
                }, 3000)
            } catch (e) {
                toast.error(e.response.data['hydra:description'])
                setLoading(false)
            }

        }
    })

    return (
        <>
            <h2>Order { order.projectId }</h2>
            {order &&
                <Stack>
                    <Box mt={4}>
                        <form onSubmit={handleSubmit}>
                            <Flex>
                                <FormControl mr={2} isInvalid={!!errors.firstName} isRequired>
                                    <FormLabel htmlFor={"firstName"}>
                                        Firstname
                                    </FormLabel>
                                    <Input
                                        id={"firstName"}
                                        name={"firstName"}
                                        onChange={handleChange}
                                        defaultValue={values.firstName}
                                        ref={editOrder}
                                        placeholder={"Firstname"} />
                                    <FormErrorMessage>{errors?.firstName}</FormErrorMessage>
                                </FormControl>
                                <FormControl mr={2} isInvalid={!!errors.lastName} isRequired>
                                    <FormLabel htmlFor={"lastName"}>
                                        Lastname
                                    </FormLabel>
                                    <Input
                                        id={"lastName"}
                                        name={"lastName"}
                                        onChange={handleChange}
                                        defaultValue={values.lastName}
                                        placeholder={"Lastname"} />
                                    <FormErrorMessage>{errors?.lastName}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.email} isRequired>
                                    <FormLabel htmlFor={"email"}>
                                        Email
                                    </FormLabel>
                                    <Input
                                        id={"email"}
                                        type={"email"}
                                        name={"email"}
                                        onChange={handleChange}
                                        defaultValue={values.email}
                                        placeholder={"Email "} />
                                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <FormControl mt={2} isInvalid={!!errors.companyName} isRequired>
                                <FormLabel htmlFor={"companyName"}>
                                    Company Name
                                </FormLabel>
                                <Input
                                    id={"companyName"}
                                    name={"companyName"}
                                    onChange={handleChange}
                                    defaultValue={values.companyName}
                                    placeholder={"Company Name"} />
                                <FormErrorMessage>{errors?.companyName}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt={2} isInvalid={!!errors.certificateText} isRequired>
                                <FormLabel htmlFor={"certificateText"}>
                                    Certificate Text
                                </FormLabel>
                                <Textarea
                                    id={"certificateText"}
                                    name={"certificateText"}
                                    onChange={handleChange}
                                    defaultValue={values.certificateText}
                                    placeholder={"Sertifikada bulunacak text"} />
                                <FormErrorMessage>{errors?.certificateText}</FormErrorMessage>
                            </FormControl>
                            <Flex mt={2}>
                                <FormControl mr={2} isInvalid={!!errors.quantity} isRequired>
                                    <FormLabel htmlFor={"quantity"}>
                                        Quantity
                                    </FormLabel>
                                    <Input
                                        type={"number"}
                                        id={"quantity"}
                                        name={"quantity"}
                                        onChange={handleChange}
                                        defaultValue={values.quantity}
                                        ref={editOrder}
                                        placeholder={"Quantity"} />
                                    <FormHelperText>Kg olarak giriniz</FormHelperText>
                                    <FormErrorMessage>{errors?.quantity}</FormErrorMessage>
                                </FormControl>
                                <FormControl mr={2} isInvalid={!!errors.totalPrice} isRequired>
                                    <FormLabel htmlFor={"totalPrice"}>
                                        Total Price
                                        <InputGroup mt={2}>
                                            <Input
                                                id={"totalPrice"}
                                                type={"number"}
                                                name={"totalPrice"}
                                                onChange={handleChange}
                                                defaultValue={values.totalPrice}
                                                ref={editOrder}
                                                placeholder={"Total Price"}
                                            />
                                            <InputRightElement>
                                                <SelectField
                                                    mr={6}
                                                    name={"currencyCode"}
                                                    defaultValue={values.currencyCode}
                                                    onChange={handleChange}
                                                >
                                                    <option value={"GBP"}>GBP</option>
                                                    <option value={"USD"}>USD</option>
                                                    <option value={"TRY"}>TRY</option>
                                                </SelectField>
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>{errors?.totalPrice}</FormErrorMessage>
                                    </FormLabel>
                                </FormControl>
                                <FormControl mr={2} isInvalid={!!errors.projectId} isRequired>
                                    <FormLabel htmlFor={"projectId"}>
                                        Project
                                    </FormLabel>
                                    <Select
                                        id={"projectId"}
                                        name={"projectId"}
                                        onChange={handleChange}
                                        ref={editOrder}
                                        value={order ? values.projectId : ''}
                                        placeholder={"Proje Seçiniz"}
                                    >
                                        {
                                            projects.map((project) => {
                                                return (
                                                    <option
                                                        key={project.id}
                                                        value={project.id}
                                                    >{project.name} -- {project.countryId.name}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormErrorMessage>{errors?.projectId}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex mt={2}>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isInvoice' mb='0'>
                                        Co2nsensus Fatura Kesiyor mu?
                                    </FormLabel>
                                    <Switch
                                        id='isInvoice'
                                        name={"isInvoice"}
                                        onChange={handleChange}
                                        ref={editOrder}
                                        size={"lg"}
                                        isChecked={!!values.isInvoice}
                                    />
                                </FormControl>
                                {
                                    values.isInvoice &&
                                    <FormControl mr={2} >
                                        <FormLabel htmlFor={"taxNumber"}>
                                            Vergi Dairesi / Vergi No
                                        </FormLabel>
                                        <Input
                                            id={"taxNumber"}
                                            name={"taxNumber"}
                                            onChange={handleChange}
                                            value={values.taxNumber}
                                            placeholder={"Vergi Dairesi / Vergi No"} />
                                    </FormControl>
                                }
                            </Flex>
                            <Button
                                mt={"4rem"}
                                borderRadius={0}
                                type="submit"
                                variant="solid"
                                colorScheme="teal"
                                isLoading={loading}
                                loadingText={'Submitting...'}
                            >
                                Kaydet
                            </Button>
                        </form>
                    </Box>
                </Stack>

            }
            <ToastContainer theme={'colored'} />
        </>
    )
}