import OrderFilter from "./OrderFilter";
import {
    Flex,
    IconButton, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td, Text,
    Th,
    Thead,
    Tooltip,
    Tr
} from "@chakra-ui/react";
import {
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon
} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {OrderItem} from "./OrderItem";
import {useFilter} from "../../contexts/filterContext";
import {getOrders} from "./orderService";

const totalPage = (totalItems, limit) => {
    if (totalItems === 0) {
        return totalItems
    }

    return  Math.round( totalItems / limit )
}

const Orders = () => {
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [canPreviousPage, setCanPreviousPage] = useState(true)
    const [pageCount, setPageCount] = useState(1)
    const { channel }  = useFilter()

    useEffect(() => {

        (async () => {
            setLoading(true)
            let channelFilter = ''

            if (channel !== '') {
                channelFilter = '&channelId='+channel
            }

            try {
                const { data } = await getOrders({page, channelFilter})
                setOrders(data["hydra:member"])
                setPageCount(totalPage(data["hydra:totalItems"], 50))
                setLoading(false)
            } catch (e) {
                console.log(e)
            }
        })()

        setCanPreviousPage(page !== 1)
    },[page, channel])


    const goToPage = (page) => {
        setPage(page)
    }

    return (
        <>
            <OrderFilter />
            <TableContainer shadow={"md"} border={"md"} mt={"1rem"}>
                <Table size={"sm"} variant={"striped"}>
                    <Thead>
                        <Tr>
                            <Th>Status</Th>
                            <Th>Customer</Th>
                            <Th>Date</Th>
                            <Th>Quantity</Th>
                            <Th>T.Price</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        { loading && <Tr><Td colSpan={8}><Spinner size={"xl"} /></Td></Tr> }
                        { !loading &&
                            orders.map((order) => {
                                return (
                                    <OrderItem key={order.id} order={order}></OrderItem>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex justifyContent={"space-between"} m={4} alignItems={"center"}>
                <Flex>
                    <Tooltip label={"First Page"}>
                        <IconButton
                            onClick={() => goToPage(1)}
                            isDisabled={!canPreviousPage}
                            icon={<ArrowLeftIcon h={3} w={3} />}
                        ></IconButton>
                    </Tooltip>
                    <Tooltip label={"Previous Page"}>
                        <IconButton
                            onClick={() => goToPage(page - 1 )}
                            isDisabled={!canPreviousPage}
                            icon={<ChevronLeftIcon h={6} w={6} />}
                        >
                        </IconButton>
                    </Tooltip>
                </Flex>

                <Flex alignItems={"center"}>
                    <Text flexShrink={"0"} mr={8}>
                        Page{" "}
                        <Text fontWeight={"bold"} as={"span"}>
                            { page }
                        </Text>{" "}
                        of{" "}
                        <Text fontWeight={"bold"} as={"span"}>
                            { pageCount }
                        </Text>
                    </Text>
                    <Text flexShrink="0">Go to page:</Text>{" "}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={28}
                        min={1}
                        max={pageCount}
                        onChange={(value) => {
                            const newPage = value ? value : 1
                            goToPage(newPage)
                        }}
                        defaultValue={page}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>

                <Flex>
                    <Tooltip label="Next Page">
                        <IconButton
                            onClick={() => goToPage(Math.round(page) + 1)}
                            isDisabled={page === pageCount}
                            icon={<ChevronRightIcon h={6} w={6} />}
                        />
                    </Tooltip>
                    <Tooltip label="Last Page">
                        <IconButton
                            onClick={() => goToPage(pageCount )}
                            isDisabled={page === pageCount}
                            icon={<ArrowRightIcon h={3} w={3} />}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>

            </Flex>
        </>
    )
}

export default Orders