import {createContext, useContext, useState} from "react";

const FilterContext = createContext(null)

export const FilterProvider = ( { children } ) => {
    
    const [channel, setChannel] = useState('')
    
    return <FilterContext.Provider value={{channel, setChannel}}>{children}</FilterContext.Provider>
}

export const useFilter = () => useContext(FilterContext)