import {Button, ButtonGroup, IconButton, Td, Tr} from "@chakra-ui/react";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

export const CertificateTableItem = ( { certificate }) => {

    const navigate = useNavigate()

    const viewCertificate = id => {
        window.open(`${process.env.REACT_APP_BASE_URL}/certificate/${id}/show`, '_blank', 'noopener,noreferrer')
    }
    return (
        <Tr>
            <Td>
                { certificate.c_certificateNumber}
            </Td>
            <Td>
                { certificate.companyName ? certificate.companyName : certificate.firstName +' ' + certificate.lastName }
            </Td>
            <Td>
                { certificate.c_issuedAt.date }
            </Td>
            <Td>
                <ButtonGroup size='sm' isAttached variant={'outline'} >
                    <Button onClick={ () => viewCertificate(certificate.c_id)}>Görüntüle</Button>
                    <IconButton onClick={ () => viewCertificate(certificate.c_id)} aria-label={ 'Sertifikayı Götüntüle' } icon={<ViewIcon />} />
                </ButtonGroup>
                <ButtonGroup size='sm' isAttached variant={'outline'} >
                    <Button onClick={ () => navigate(`/app/orders/edit/${certificate.c_orderNumber}`) }>Düzenle</Button>
                    <IconButton onClick={ () => navigate(`/app/orders/edit/${certificate.c_orderNumber}`) } aria-label={ 'Sertifikayı Götüntüle' } icon={<EditIcon />} />
                </ButtonGroup>
            </Td>
        </Tr>
    )
}