import {Box, Button, Flex, HStack, useColorModeValue} from "@chakra-ui/react";
import {FaShareSquare} from "react-icons/all";
import {useAuth} from "../../contexts/authContext";

export default function Header() {
    const { doLogOut } = useAuth()

    return (
        <Box
            bg={useColorModeValue('gray.100', 'gray.900')}
            boxShadow={'base'}
            px={4}
        >
            <Flex h={14} alignItems={'center'} justifyContent={'space-between'}>
                <HStack spacing={8} alignItems={'center'}>
                    <Box>Co2nsensus</Box>
                </HStack>
                <Flex alignItems={'center'}>
                    <Button
                        variant={'solid'}
                        colorScheme={'teal'}
                        size={'sm'}
                        mr={4}
                        rightIcon={<FaShareSquare/>}
                        onClick={doLogOut}
                    >
                        Çıkış
                    </Button>
                </Flex>
            </Flex>
        </Box>
    )
}